import React, {useEffect, useRef} from "react"
import { Power2, TweenLite} from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";

const NotFound = ({ exitTransition, entryTransition }) => {

    let notFoundImage = useRef(null);
    let notFoundTitle = useRef(null);
    let notFoundText = useRef(null);
    let notFoundButton = useRef(null);

    useEffect(() => {
        TweenLite.from(notFoundImage, 0.8, {
            opacity: 0,
            scale: 1.6,
            ease: Power2.easeInOut,
            delay: -0.2
        });
        TweenLite.from(notFoundTitle, 0.4, {
            opacity: 0,
            y:32,
            ease: Power2.easeInOut,
            delay:0.1
        });
        TweenLite.from(notFoundText, 0.4, {
            opacity: 0,
            y: 64,
            ease: Power2.easeInOut,
            delay:0.1
        });
        TweenLite.from(notFoundButton, 0.4, {
            opacity: 0,
            y: 80,
            ease: Power2.easeInOut,
            delay:0.1
        });
    }, [notFoundImage, notFoundTitle, notFoundText, notFoundButton]);

     return (
        <section className="not-found-page ">
            <div className="img-wrapper">
                <h1 ref={el => (notFoundImage = el)} >404</h1>
            </div>
            <div className="text-wrapper">
                <h5 ref={el => (notFoundTitle = el)}>The page not found :(</h5>
                <p ref={el => (notFoundText = el)}>Check is it the correct URL!</p>
                <div ref={el => (notFoundButton = el)}>
                    <TransitionLink
                        className="btn-cta"
                        fade to={`/`}
                        exit={exitTransition}
                        entry={entryTransition}
                    >
                        Home
                    </TransitionLink>
                </div>
            </div>
        </section>
     );
}

export default NotFound
